import React from "react";
import { PageHeader } from "../../Components/PageHeader/PageHeader";

export const ShopOffers = () => {
  return (
    <React.Fragment>
      <PageHeader title="Offers" />
    </React.Fragment>
  );
};
